import firebase from '../constants/firebase';
import { DISTRICTS, RESULTS, SCHOOLS } from '../constants/collections';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import moment from 'moment';

export const processAssessmentResult = async (newAssessment) => {
	const db = firebase.firestore();
	const data = newAssessment.data(),
		id = newAssessment.id;
	const writeAssessmentResult = db.batch();
	const positiveAnswer = keys(pickBy(data.assessment));
	// const currentDateAll = moment(new Date()).subtract(14, 'days');
	const currentDateAll = moment(new Date());
	const currentDateData = {
		year: currentDateAll.format('YYYY'),
		month: currentDateAll.format('MMMM'),
		date: currentDateAll.format('LL'),
		dateLabel: currentDateAll.format('MMM DD, ddd')
	};
	positiveAnswer.forEach((ans) => {
		const resultRef = db.collection(RESULTS).doc();
		const resultValues = {
			assessmentId: id,
			fullname: data.fullname,
			position: data.position,
			email: data.email,
			phone: data.phone,
			question: ans,
			...currentDateData,
			dateAssessed: firebase.firestore.FieldValue.serverTimestamp(),
			// dateAssessed: new Date(currentDateAll),
			districtSchool: ['all&all', `${data.district}&all`, `${data.district}&${data.school}`]
		};
		writeAssessmentResult.set(resultRef, resultValues);
	});

	return await writeAssessmentResult.commit();
};

export const updateDistrictAssessmentStats = async (newAssessment) => {
	const db = firebase.firestore();
	const data = newAssessment.data(),
		id = newAssessment.id;
	const districtId = data.district;
	const districtRef = db.collection(DISTRICTS).doc(districtId);
	const districtSnap = await districtRef.get();
	const districtData = districtSnap.data();
	districtRef.set(
		{
			latestAssessment: id,
			lastAssessmentSubmitted: firebase.firestore.FieldValue.serverTimestamp(),
			totalSubmission: (districtData.totalSubmission || 0) + 1
		},
		{ merge: true }
	);
};

export const updateSchoolAssessmentStats = async (newAssessment) => {
	const db = firebase.firestore();
	const data = newAssessment.data(),
		id = newAssessment.id;
	const schoolId = data.school;
	const schoolRef = db.collection(SCHOOLS).doc(schoolId);
	const schoolSnap = await schoolRef.get();
	const schoolData = schoolSnap.data();
	schoolRef.set(
		{
			latestAssessment: id,
			lastAssessmentSubmitted: firebase.firestore.FieldValue.serverTimestamp(),
			totalSubmission: (schoolData.totalSubmission || 0) + 1
		},
		{ merge: true }
	);
};
