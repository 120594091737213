import { LOGIN_USER_START, LOGIN_USER_SUCCESS, LOGIN_USER_ERROR } from './actions';

const initState = {
	loading: false,
	error: ''
};

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case LOGIN_USER_START:
			return {
				...state,
				loading: true,
				error: ''
			};
		case LOGIN_USER_SUCCESS:
			return {
				...state,
				loading: false,
				error: ''
			};
		case LOGIN_USER_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};

		default:
			return state;
	}
};

export default authReducer;
