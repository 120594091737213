import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import assessmentReducer from './assessment/reducer';
import authReducer from './auth/reducer';

const rootReducer = combineReducers({
	assessment: assessmentReducer,
	auth: authReducer,
	firebase: firebaseReducer,
	firestore: firestoreReducer
});

export default rootReducer;
