import { ADD_ASSESSMENT_START, ADD_ASSESSMENT_SUCCESS, ADD_ASSESSMENT_FAIL } from './actions';

const initState = {
	submitting: false,
	submitted: false,
	submitError: null,
	assessedName: null
};

const assessmentReducer = (state = initState, action) => {
	switch (action.type) {
		case ADD_ASSESSMENT_START:
			return {
				...state,
				submitting: true,
				submitted: false,
				submitError: null,
				assessedName: action.fullname
			};
		case ADD_ASSESSMENT_SUCCESS:
			return {
				...state,
				submitting: false,
				submitted: true
			};
		case ADD_ASSESSMENT_FAIL:
			return {
				...state,
				submitting: false,
				submitted: false,
				submitError: action.error.message
			};
		default:
			return state;
	}
};

export default assessmentReducer;
