import { ASSESSMENTS } from '../../constants/collections';
import {
	processAssessmentResult,
	updateDistrictAssessmentStats,
	updateSchoolAssessmentStats
} from '../../helpers/assessment';
export const ADD_ASSESSMENT_START = 'ADD_ASSESSMENT_START',
	ADD_ASSESSMENT_SUCCESS = 'ADD_ASSESSMENT_SUCCESS',
	ADD_ASSESSMENT_FAIL = 'ADD_ASSESSMENT_FAIL';

const addAssessmentStart = (fullname) => ({
	type: ADD_ASSESSMENT_START,
	fullname
});

const addAssessmentSuccess = () => ({
	type: ADD_ASSESSMENT_SUCCESS
});

const addAssessmentFail = (error) => ({
	type: ADD_ASSESSMENT_FAIL,
	error
});

export const addAssessment = (assessment) => async (dispatch, getState, { getFirestore }) => {
	dispatch(addAssessmentStart(assessment.fullname));
	try {
		const db = getFirestore();
		const assessmentRef = db.collection(ASSESSMENTS).doc();
		await assessmentRef.set({
			...assessment,
			dateAssessed: db.FieldValue.serverTimestamp()
		});
		dispatch(addAssessmentSuccess());

		const newAssessmentSnap = await assessmentRef.get();

		// Process new assessment as RESULT
		processAssessmentResult(newAssessmentSnap);

		// Process assessment against district
		updateDistrictAssessmentStats(newAssessmentSnap);

		// Process assessment against school
		updateSchoolAssessmentStats(newAssessmentSnap);
	} catch (error) {
		dispatch(addAssessmentFail(error));
	}
};
