export const LOGIN_USER_START = 'LOGIN_USER_START',
	LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
	LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

const loginUserStart = () => ({
	type: LOGIN_USER_START
});

const loginUserSuccess = (token) => ({
	type: LOGIN_USER_SUCCESS,
	token
});

const loginUserError = (message) => ({
	type: LOGIN_USER_ERROR,
	error: message
});

export const loginUser = (creds) => {
	return async (dispatch, getState, { getFirebase }) => {
		dispatch(loginUserStart());
		const firebase = getFirebase();
		try {
			await firebase.auth().signInWithEmailAndPassword(creds.email, creds.password);
			dispatch(loginUserSuccess());
		} catch (error) {
			console.log(error);
			dispatch(loginUserError(error.message));
		}
	};
};

export const signOut = () => async (dispatch, getState, { getFirebase }) => {
	const firebase = getFirebase();
	await firebase.logout();
	// dispatch({ type: actionTypes.CLEAR_DATA });
};
